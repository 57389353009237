import { Col, Container, Row } from "react-bootstrap";
import IconButton from "../../buttons/iconButton";

export default function HomeHero() {
    return (
        <div>
            {/* Desktop Hero */}
            <Container fluid className="p-0 d-none d-md-block">
                <Row className="d-flex align-items-center home-hero-image">
                    <Col
                        xs={7}
                        xl={6}
                        className="home-hero-overlay h-100 align-items-center d-flex"
                    >
                        <div className="px-4 home-hero-content">
                            <div className="d-flex align-items-center pb-3">
                                <span id="hero-rectangle"></span>
                                <h2 className="home-hero-sub">
                                    WELCOME TO CHARLES JONES
                                </h2>
                            </div>

                            <h1 className="mb-4 home-hero-hdg">
                                Public Record Searches
                            </h1>
                            <p className="home-hero-txt">
                                Leaders in accurate &amp; dependable public records search
                            </p>
                            <p className="home-hero-txt">
                                Charles Jones has been a leader in accurate and dependable
                                public records searches since 1911. From manual searches
                                to advanced web-based systems, our cutting edge technology
                                and expert staff deliver the reliable information you need.
                            </p>
                            <p className="mb-4 home-hero-txt" style={{ fontWeight: "600" }}>
                                Specializing in services for New Jersey and Pennsylvania,
                                we offer comprehensive public records search solutions
                                nationwide for coporations and individuals.
                            </p>
                            <Row>
                                <Col>
                                    <a href="/get-started">
                                        <IconButton
                                            text="GET STARTED"
                                            styleClass="btn-cj-transparent home-hero-button"
                                            iconClass="btn-arrow-right-white"
                                            type="button"
                                        />
                                    </a>
                                    <form
                                        action="/SignOn/login.roc"
                                        method="POST"
                                        className="d-inline"
                                    >
                                        <IconButton
                                            id="login-button"
                                            text="SIGN ON"
                                            styleClass="btn-cj-secondary home-hero-button"
                                            iconClass="btn-arrow-right"
                                            type="submit"
                                            style={{ marginLeft: "1.5rem" }}
                                        />
                                    </form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={5} xl={6}></Col>
                </Row>
            </Container>

            {/* Mobile Hero */}
            <Container fluid className="p-0 d-block d-md-none">
                <div
                    id="mobile-hero"
                    className="d-flex align-items-center home-hero-image"
                >
                    <Container fluid className="h-100 mobile-hero-content">
                        <Row className="h-100">
                            <Col className="home-hero-overlay h-100 align-items-center d-flex">
                                <div className="px-4">
                                    <div className="d-flex align-items-center pb-3">
                                        <span id="hero-rectangle"></span>
                                        <h2 className="home-hero-sub">
                                            WELCOME TO CHARLES JONES
                                        </h2>
                                    </div>

                                    <h1 className="mb-4 home-hero-hdg">
                                        Leaders in accurate & dependable public
                                        record searches
                                    </h1>
                                    <p className="home-hero-txt">
                                        Charles Jones has been a leader in
                                        accurate and dependable public record
                                        searches since 1911. From manual
                                        searches to advanced web-based systems,
                                        our cutting-edge technology and expert
                                        staff deliver the reliable information
                                        you need.
                                    </p>
                                    <p
                                        className="mb-4 home-hero-txt"
                                        style={{
                                            fontWeight: "600",
                                        }}
                                    >
                                        While specializing in New Jersey and
                                        Pennsylvania, we offer comprehensive
                                        services nationwide.
                                    </p>
                                    <Row>
                                        <Col
                                            id="mobile-button-row"
                                            className="d-flex"
                                        >
                                            <a href="/get-started">
                                                <IconButton
                                                    text="GET STARTED"
                                                    styleClass="btn-cj-transparent"
                                                    iconClass="btn-arrow-right-white"
                                                    type="button"
                                                />
                                            </a>

                                            <form
                                                action="/SignOn/login.roc"
                                                method="POST"
                                                className="d-inline"
                                            >
                                                <IconButton
                                                    id="mobile-login-button"
                                                    text="SIGN ON"
                                                    styleClass="btn-cj-secondary ms-3"
                                                    iconClass="btn-arrow-right"
                                                    type="submit"
                                                    style={{
                                                        marginLeft: "1.5rem",
                                                    }}
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </div>
    );
}
