import React from "react";
import "./clientcard.css";
import { HashLink } from "react-router-hash-link";

const ClientCard = ({ data }) => {
    return (
        <>
            <div className="client-card shadow-sm shadow shadow-lg bg-white mx-auto">
                <div className="card-body text-center">
                    <h5
                        className="card-title"
                        style={{
                            textAlign: "left",
                            fontWeight: "700",
                            fontSize: "20px",
                            color: "#0D0D0D",
                            lineHeight: "24px",
                        }}
                    >
                        {data.title}
                    </h5>
                    <p
                        className="card-text"
                        style={{
                            fontSize: "18px",
                            paddingTop: "10px",
                            textAlign: "left",
                            marginBottom: "20px",
                            fontWeight: "400",
                        }}
                    >
                        {data.short_desc}
                        <HashLink
                            className="client-breadcrumb-item"
                            smooth
                            to={`/testimonials#${data.dataId}`}
                        >
                            Read more
                        </HashLink>
                    </p>
                    <h5 className="data-name">{data.author}</h5>
                    <p className="data-occupation">{data.company}</p>
                </div>
            </div>
        </>
    );
};

export default ClientCard;
