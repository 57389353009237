import React from 'react'
import './titleplantnewspost.css'
import Contact from '../../components/common/contact'
import Innerpageheading from '../../components/common/innerpageheading'
import Breadcrumb from '../../components/common/breadcrumb'
import { titlePlantNewsPostPageHeader } from '../../components/common/pageheader'
import { titlePlantNewsPost } from '../../components/common/breadcrumbs'
import useTitle from "../../utils";


const TitlePlantNewsPost = () => {
    useTitle("DTS Title Plant");

    return (
        <div>
            <Innerpageheading pageHeadInfo={titlePlantNewsPostPageHeader()} />
            <Breadcrumb breadcrumbInfo={titlePlantNewsPost()} />
            <div className='title-plant-news-post-content'>
                <div className='mb-5'>
                    <p className='title-plant-news-post-desc'>With the DTS title plant, you can complete searches in the majority of New Jersey counties that include:</p>

                    <ul>
                        <li className='title-plant-news-post-county'>
                            Atlantic
                        </li>

                        <li className='title-plant-news-post-county'>
                            Burlington
                        </li>

                        <li className='title-plant-news-post-county'>
                            Camden
                        </li>

                        <li className='title-plant-news-post-county'>
                            Essex
                        </li>

                        <li className='title-plant-news-post-county'>
                            Gloucester
                        </li>

                        <li className='title-plant-news-post-county'>
                            Hudson
                        </li>

                        <li className='title-plant-news-post-county'>
                            Hunterdon
                        </li>

                        <li className='title-plant-news-post-county'>
                            Mercer
                        </li>
                        <li className='title-plant-news-post-county'>
                            Middlesex
                        </li>

                        <li className='title-plant-news-post-county'>
                            Monmouth
                        </li>

                        <li className='title-plant-news-post-county'>
                            Morris
                        </li>

                        <li className='title-plant-news-post-county'>
                            Ocean
                        </li>

                        <li className='title-plant-news-post-county'>
                            Passaic
                        </li>

                        <li className='title-plant-news-post-county'>
                            Somerset
                        </li>

                        <li className='title-plant-news-post-county'>
                            Sussex
                        </li>

                        <li className='title-plant-news-post-county'>
                            Warren
                        </li>
                    </ul>

                </div>

                <div>
                    <h4 className='title-plant-news-post-heading'>
                        Benefits of the DTS Title Plant:
                    </h4>

                    <ul>
                        <li className='title-plant-news-post-benefits' >
                            Predictable fixed fees includes all copies in the plant.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            Improved turnaround time and retained earnings when you take control of your county search work with the DTS Title Plant.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            Our professionals index instruments three ways - versus the County’s single name index - so you’re sure to find all of your relevant documents.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            Need a present owner search? You can complete over 95% of present owner searches via DTS, right from your office. Complete what you need, when you need it - with instant results.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            You’ll have access to each of our county indexes and images covering 20 years (or more) of filings by utilizing our title plants directly.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            DTS is available when the courts are closed - so it’s there when you need it, even during off hours and holidays.
                        </li>

                        <li className='title-plant-news-post-benefits' >
                            Access and print any documents filed that are in the plant with the click of a mouse.
                        </li>
                    </ul>

                </div>

                <div>
                    <p className='title-plant-news-post-additional-info'>For additional information, <a className='title-plant-news-post-link' href='/contact-us' rel="noreferrer">click here</a> to contact our team.</p>
                </div>

            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </div>
    )
}

export default TitlePlantNewsPost;
