import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import { legalExistenceCertificatePageHeader } from "../../../components/common/pageheader";
import { legalExistenceCertificateBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import solutionsData from "../../../components/solutions/SolutionsData";
import { useTitle, useMetaDescription } from "../../../utils";


const LegalExistenceCertificate = () => {
    const data = solutionsData[4].services[3];

    useTitle(data.serviceTitle);
    useMetaDescription(data.metaDescription);

    return (
        <>
            <Innerpageheading pageHeadInfo={legalExistenceCertificatePageHeader()} />

            <Breadcrumb breadcrumbInfo={legalExistenceCertificateBreadcrumb()} />

            <ServiceDetails data={data} />

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default LegalExistenceCertificate;
