import React from 'react'
import './disabilitystatement.css'
import Innerpageheading from '../../components/common/innerpageheading';
import Breadcrumb from '../../components/common/breadcrumb';
import Contact from '../../components/common/contact';
import { disabilityStatementPageHeader } from '../../components/common/pageheader';
import { disabilityStatement } from '../../components/common/breadcrumbs';
import useTitle from "../../utils";


const DisabilityStatement = () => {
    useTitle("Disability Statement");

    return (
        <>
            <Innerpageheading pageHeadInfo={disabilityStatementPageHeader()} />
            <Breadcrumb breadcrumbInfo={disabilityStatement()} />

            <div className='disability-statement-content'>
                <h4 className='disability-statement-title'>DataTrace Accessibility Statement</h4>
                <p className='disability-statement-desc'>Data Trace Information Services LLC and its affiliates, are committed to making our website's content accessible and user friendly to everyone. If you are having difficulty viewing or navigating the content on this website, or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities, please call our Customer Service team at 866-718-0097 or email our team at <a className='disability-statement-link' href="Mailto:accessibility@datatracetitle.com"  rel="noreferrer">accessibility@datatracetitle.com</a> and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement. We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies. Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.</p>
            </div>

            <div className='row contact-loaction'>
                <Contact />
            </div>
        </>
    )
}

export default DisabilityStatement;
