import React from "react";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import Contact from "../../components/common/contact";
import faqData from "./data";
import { faqs } from "../../components/common/breadcrumbs";
import { faqsPageHeader } from "../../components/common/pageheader";
import Ourexperts from "../../components/common/ourexperts";
import "./faqs.css";
import { allArticles } from "../../components/newsinsights/NewsConstants";
import useTitle from "../../utils";


const Faq = ({ index, data }) => {
    let linksList = [];

    for (let i = 0; i < data.links.length; i++) {
        linksList.push(
            <div key={i} className="faqs-links">
                <a
                    className="faqs-judgment-search-link"
                    href={data.links[i]}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {data.links[i]}
                </a>
                <span className="faqs-judgment-search-link faqs-arrow mx-1">↗</span>
            </div>
        );
    }

    return (
        <div id={`question-${index}`} className="faqs-judgment-search">
            <h4 className="faqs-judgment-search-title">
                {data.question}
            </h4>
            <p className="faqs-judgment-search-desc">
                {data.answer}
            </p>
            {linksList}
        </div>
    );
}

const Faqs = () => {
    let jumpToList = [];
    let faqList = [];

    for (let i = 0; i < faqData.length; i++) {
        jumpToList.push(
            <li key={i} className="faqs-impinfo-desc">
                <a href={`#question-${i}`}>
                    {faqData[i].question}
                </a>
            </li>
        );
        faqList.push(
            <Faq key={i} index={i} data={faqData[i]} />
        );
    }

    useTitle("FAQ");

    return (
        <>
            <Innerpageheading pageHeadInfo={faqsPageHeader()} />
            <Breadcrumb breadcrumbInfo={faqs()} />

            <div className="row faqs-content justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 faqs-left-content">
                    <h2 className="faqs-heading">Frequently Asked Questions</h2>
                    <p className="faqs-heading-desc1">
                        Many of the products Charles Jones offers are certified.
                        What does that mean? Simply put, we stand behind our
                        work to help mitigate risks and give you peace of mind
                        at the closing table.
                    </p>
                    <p className="faqs-heading-desc2">
                        Our wide range of products assist the title, legal and
                        lending markets with due diligence and compliance
                        solutions. Many of our products were developed or
                        enhanced to assist in compliance with regulatory
                        challenges for these markets. Below are sources that may
                        provide you with further context of the solutions our
                        products may provide to you.
                    </p>

                    <ul className="faqs-impinfo">
                        {jumpToList}
                    </ul>

                    <div>
                        {faqList}
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <div className="left-badge">
                        <img
                            src="/img/cj-10years.svg"
                            alt="..."
                            className="cj110-years"
                        />
                    </div>
                    <div className="newsinsight-expert-heading">
                        <h2 className="newsinsight-expert-title">
                            Read news & Insights from our Experts
                        </h2>
                    </div>
                    {allArticles?.map((article) => {
                        return (
                            <div
                                className="newsinsight-expert-info"
                                key={article?.slug}
                            >
                                <Ourexperts
                                    article={article}
                                    homePageArticle={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default Faqs;
