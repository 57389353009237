import { useEffect } from "react";


export function useTitle(title) {
    useEffect(() => {
        const original = document.title;
        document.title = `${title} - Charles Jones`;

        return () => {
            document.title = original;
        };
    });
}

export function useMetaDescription(description) {
    useEffect(() => {
        // Find the meta description
        let meta = document.querySelector("meta[name='description']");

        // Save the original
        const original = meta.content;

        // Change to new description if it is not empty
        if (description) {
            meta.content = description;
        }

        return () => {
            // Reset back to original
            meta.content = original
        }
    });
}

export default useTitle;
