import React from "react";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import Ourexperts from "../../components/common/ourexperts";
import Contact from "../../components/common/contact";
import "./about.css";
import { aboutUS } from "../../components/common/breadcrumbs";
import { aboutUSPageHeader } from "../../components/common/pageheader";
import { allArticles } from "../../components/newsinsights/NewsConstants";
import useTitle from "../../utils";


const About = () => {
    useTitle("About");

    return (
        <>
            <Innerpageheading pageHeadInfo={aboutUSPageHeader()} />
            <Breadcrumb breadcrumbInfo={aboutUS()} />

            <div className="row about-content justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">
                    <h4 className="about-content-heading">
                        Charles Jones has advanced the art and science of
                        searching, and earned a distinctive reputation for
                        accuracy and dependability. It is our signature service.
                    </h4>
                    <p className="about-content-desc">
                        From the early days of searching public records by hand
                        to our present web-based search system, Sign On, our
                        experienced staff and leading-edge technology continue
                        to provide the reliable information you need.
                    </p>
                    <p className="about-content-desc">
                        The company got its start in 1911, when Charles Jones, a
                        Newark attorney, manually searched and indexed public
                        records filed in New Jersey. The firm continued this
                        practice until 1969, when Charles’ son Donald Jones,
                        went against the advice of other experts and installed
                        the company’s first computer system. It proved fruitful
                        for both the company and its customers, as he was able
                        to speed the process of searching, and lead the firm
                        into the technology age. A pioneer and visionary, Donald
                        Jones led the company along this new frontier by
                        expanding his investment and spearheading the
                        development of a complete electronic database. By 1972,
                        the firm accomplished its first fully computerized
                        search, positioning Charles Jones to continue as a
                        leader in the information industry. In the late 1970's,
                        Donald continued this forward thinking and made Charles
                        Jones one of the first companies in our industry to
                        provide online access and information services.
                    </p>
                    <img
                        src="/img/about-frame.png"
                        alt="..."
                        className="about-frame"
                    />

                    <p className="about-content-desc">
                        In 2007, the company became part of a joint venture that
                        included DataTrace Information Services - the premier
                        innovator and leading provider of data and automation to
                        the title industry. During that time, the business name
                        of Charles Jones took a hiatus when the joint venture
                        took the name of Signature Information Solutions LLC. In
                        2017, DataTrace assumed full ownership of the firm, and
                        returned the company to its roots with the renaming back
                        to Charles Jones. While our corporate name has evolved,
                        the core values of Charles Jones and the heritage of
                        excellence has remained.
                    </p>

                    <p className="about-content-desc">
                        Today, the company continues to create dependable
                        solutions, adding value to public record data so that
                        our customers can make the best decisions with
                        confidence and ease. We also foster a corporate culture
                        that encourages employees to innovate and implement ways
                        to improve our customer focus and performance.
                    </p>
                    <p className="about-content-desc">
                        Charles Jones was founded upon a commitment to provide
                        prompt, personalized service and certified accuracy.
                        This promise continues at the forefront of our work
                        today. With a staff of experts in customer service, data
                        collection, searching, systems development, data
                        analysis and quality control - we continue to improve
                        and refine our services to provide you with the best
                        possible value.
                    </p>
                    <p className="about-content-desc">
                        At Charles Jones, dedication to the art and science of
                        searching to produce the most accurate results is our
                        signature service - today and into the future.
                    </p>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
                    <div className="left-badge">
                        <img
                            src="/img/cj-10years.svg"
                            alt="..."
                            className="cj110-years"
                        />
                    </div>
                    <div className="newsinsight-expert-heading">
                        <h2 className="newsinsight-expert-title">
                            Read news & Insights from our Experts
                        </h2>
                    </div>
                    {allArticles?.map((article) => {
                        return (
                            <div
                                className="newsinsight-expert-info"
                                key={article?.slug}
                            >
                                <Ourexperts
                                    article={article}
                                    homePageArticle={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default About;
