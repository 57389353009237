import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import { dueDiligencePageHeader } from "../../../components/common/pageheader";
import { dueDiligenceBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import solutionsData from "../../../components/solutions/SolutionsData";
import { useTitle, useMetaDescription } from "../../../utils";


const DueDiligence = () => {
    const data = solutionsData[5].services[0];

    useTitle("Commercial Due Diligence & Court Search Services Nationwide");
    useMetaDescription(data.metaDescription);

    return (
        <>
            <Innerpageheading pageHeadInfo={dueDiligencePageHeader()} />

            <Breadcrumb breadcrumbInfo={dueDiligenceBreadcrumb()} />

            <ServiceDetails data={data} />

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default DueDiligence;
