import React from "react";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import Contact from "../../components/common/contact";
import { testimonials } from "../../components/common/breadcrumbs";
import { testimonialsPageHeader } from "../../components/common/pageheader";
import "./testimonials.css";
import TestimonialsCard from "../../components/testimonials/testimonialscard";
import {testimonialInfo} from "../../components/common/contenthelper";
import useTitle from "../../utils";


const Testimonials = () => {
    let cards = [];

    for (let i = 0; i < testimonialInfo.length; i++) {
        cards.push(
            <div
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                id={testimonialInfo[i].dataId}
                key={i}
            >
                <TestimonialsCard data={testimonialInfo[i]} />
            </div>
        );
    }

    useTitle("Testimonials");

    return (
        <>
            <Innerpageheading pageHeadInfo={testimonialsPageHeader()} />
            <Breadcrumb breadcrumbInfo={testimonials()} />

            <div className="row testimonials-content text-center">
                {cards}
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default Testimonials;
