import React, { useState } from "react";
import "./formsandlinks.css";
import Contact from "../../components/common/contact";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import { formsAndLinksPageHeader } from "../../components/common/pageheader";
import { formsAndLinks } from "../../components/common/breadcrumbs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Container } from "react-bootstrap";
import formsandlinksdata from "./formsandlinksdata";
import useTitle from "../../utils";


const AccordionServiceItem = ({ service }) => {
    return (
        <>
            <div className="row">
                <div className="col">
                    <ul>
                        <li className="form-link-item">
                            <a
                                className="forms-link-url"
                                href={service.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {service.subTitle}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

const AccordionItem = ({ solutionItem, isExpand, onClick }) => {
    return (
        <div className="accordion-wrapper">
            <button
                className={`forms-links-item-container ${
                    isExpand ? "active" : ""
                }`}
                onClick={onClick}
            >
                <div className="row py-1">
                    <div className="col-xl-11 col-lg-11 col-10 align-items-center">
                        <p className="accordion-header">{solutionItem.Title}</p>
                    </div>
                    <div className="col-xl-1 col-2 d-flex align-items-center justify-content-end">
                        <RiArrowDropDownLine
                            className={`arrow ${isExpand ? "active" : ""}`}
                            style={{ width: "55px", height: "55px" }}
                        />
                    </div>
                </div>
            </button>

            <div
                className="forms-link-item-content-container"
                style={isExpand ? { height: "auto" } : { height: "0px" }}
            >
                <div className="forms-link-item-content">
                    {solutionItem.Data.map((serviceItem, index) => (
                        <AccordionServiceItem
                            key={index}
                            service={serviceItem}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const FormsAndLinks = () => {
    const [openItems, setOpenItems] = useState(
        formsandlinksdata.map(() => true)
    );

    const toggleAll = () => {
        const allOpen = openItems.every((item) => item === true);
        setOpenItems(openItems.map(() => !allOpen));
    };

    const handleItemClick = (index) => {
        const newOpenItems = [...openItems];
        newOpenItems[index] = !newOpenItems[index];
        setOpenItems(newOpenItems);
    };

    useTitle("Forms and Links");

    return (
        <div>
            <Innerpageheading pageHeadInfo={formsAndLinksPageHeader()} />
            <Breadcrumb breadcrumbInfo={formsAndLinks()} />
            <Container className="forms-links-content">
                <div className="row">
                    <div className="col-12 toggel-all">
                        <div onClick={toggleAll}>
                            <span className="toggle-wrapper ">
                                {openItems.every((item) => item)
                                    ? "CLOSE ALL"
                                    : "OPEN ALL"}
                            </span>
                            <RiArrowDropDownLine
                                className={
                                    openItems.every((item) => item)
                                        ? "toogle-dropdownline-close"
                                        : "toogle-dropdownline-open"
                                }
                            ></RiArrowDropDownLine>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="accordion-container">
                            {formsandlinksdata.map((item, index) => (
                                <AccordionItem
                                    key={index}
                                    solutionItem={item}
                                    isExpand={openItems[index]}
                                    onClick={() => handleItemClick(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </div>
    );
};

export default FormsAndLinks;
