export const nameSearch = () => {
    return {
        badge: "/img/name-badge.svg",
        title: "Name Searches",
        desc: "We provide best in class certified child support, judgment lien, and patriot name search services.",
        link: "/solutions/name-searches",
    };
};

export const taxSearch = () => {
    return {
        badge: "/img/tax-badge.svg",
        title: "Tax Searches",
        desc: "Certified tax and assessment searches in NJ and certified tax and utility certifications in PA.",
        link: "/solutions/tax-searches",
    };
};

export const floodSearch = () => {
    return {
        badge: "/img/flood-tideland.svg",
        title: "Flood & Tideland",
        desc: "We provide tideland & wetland searches in NJ, plus flood and life of loan monitoring nationwide.",
        link: "/solutions/flood-and-tideland",
    };
};

export const countySearch = () => {
    return {
        badge: "/img/county-badge.svg",
        title: "County Search",
        desc: "We offer County Search services for all 21 counties in NJ and the DTS title plant covering 80% of the state.",
        link: "/solutions/county-search",
    };
};

export const corporateSearch = () => {
    return {
        badge: "/img/corporate-ucc.svg",
        title: "Corporate & UCC",
        desc: "Fast, reliable Corporate Services solutions provided in both New Jersey and Pennsylvania.",
        link: "/solutions/corporate-and-ucc",
    };
};

export const additionalServices = () => {
    return {
        badge: "/img/additional-badge.svg",
        title: "Nationwide Services",
        desc: "We offer due diligence services including corporate document retrieval, filing and registered agent services.",
        link: "/solutions/nationwide-services",
    };
};

export const testimonialInfo = [
    {
        title: "“clients that demand Charles Jones”",
        short_desc: "We have clients that demand Charles Jones for their upper court and tidelands reports.  Charles Jones's staff makes ordering...",
        desc: "We have clients that demand Charles Jones for their upper court and tidelands reports.  Charles Jones's staff makes ordering their reports easy and provides great customer service when we have questions.",
        author: "Peter Casey Wall, CTP",
        company: "Foundation Title Group",
        dataId: "wall-data-blog"
    }, {
        title: "“always seems to know what we need”",
        short_desc: "The first thing we tell everyone is that Charles Jones handles our searches. We've partnered with Charles Jones for years...",
        desc: "The first thing we tell everyone is that Charles Jones handles our searches. We've partnered with Charles Jones for years and we see our relationship as just that – a partnership. We do rely on the accuracy and turnaround time and the expertise and experience CJ brings, but we also know that we can work with their leaders to develop ideas and thoughtful solutions that work for both of our companies. Charles Jones always seems to know what we need.",
        author: "The Team at Fortune Title Agency",
        company: "Fortune Title Agency",
        dataId: "fortune-data-blog"
    }, {
        title: "“pleasant and friendly to work with”",
        short_desc: "The team at Charles Jones is superior in providing exceptional service in the various industry searches & services they provide...",
        desc: "The team at Charles Jones is superior in providing exceptional service in the various industry searches & services they provide. In addition to their searches being accurate they’re always pleasant and friendly to work with. We’ve been working with their team for over two decades and would highly recommend them for all of your searching needs.",
        author: "Janet Barrows",
        company: "CB Title Group, LLC",
        dataId: "barrows-data-blog"
    }, {
        title: "“will never use any other Company”",
        short_desc: "I have been using Charles Jones since I started in the title insurance business, at least 25 years ago!  I have never had a problem with the service....",
        desc: "I have been using Charles Jones since I started in the title insurance business, at least 25 years ago!  I have never had a problem with the service or products!  In fact, they are amazing and go above and beyond to complete our search work on time.  Charles Jones is like dealing with a great Mom and Pop Company – they actually pick up the phone and call you with questions!  I love the awesome communication each employee prides themselves on.  Charles Jones is the best search tool in our industry AND WE, Platinum Abstract Co., LLC, will never use any other Company. No need to!  FIVE STARS!",
        author: "Marlena M. Clauss",
        company: "Platinum Abstract Co., LLC",
        dataId: "clauss-data-blog"
    }, {
        title: "“consistently deliver outstanding results”",
        short_desc: "Partnering with Charles Jones has been a transformative experience for our organization. Their expertise and proactive approach consistently deliver...",
        desc: "Partnering with Charles Jones has been a transformative experience for our organization. Their expertise and proactive approach consistently deliver outstanding results. Charles Jones excels in addressing complex challenges with innovative solutions, inspiring our team and exceeding our expectations. For exceptional service and strategic insight, Charles Jones is the go-to choice.",
        author: "Peter Uzzolino",
        company: "Acres Land Title Agency",
        dataId: "uzzolino-data-blog"
    }
];
