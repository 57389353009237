import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import { requestIdPageHeader } from "../../components/common/pageheader";
import { requestId } from "../../components/common/breadcrumbs";
import { CGI_PATH, CJ_PHONE } from "../../constants";
import PrivacyPolicy from "../../components/common/PrivacyPolicy";
import Toasts from "../../components/forms/Toasts";
import {
    allowASCIIValues,
    checkAndAddFieldErrorMessage,
    isValidEmail,
    normalizeInput,
    removeAlpha,
    submitForm,
} from "../../components/forms/FormHelpers";

import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaKey } from "../../constants";
import useTitle from "../../utils";


export default function RequestId() {
    const defaultForm = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        company_name: "",
        identifier: "",
        message: "",
        captcha: "",
    };

    const captchaRef = useRef(null);
    function resetCaptcha() {
        setShowCaptchaError(false);
    }

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState(defaultForm);

    const setField = (filed, value) => {
        setForm({
            ...form,
            [filed]: value,
        });

        if (!!errors[filed]) {
            setErrors({
                ...errors,
                [filed]: null,
            });
        }
    };

    const buildFormData = () => {
        form.token = captchaRef.current.getValue();
        return form;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (
                await submitForm(
                    `${CGI_PATH}charles-jones-id.cgi`,
                    buildFormData()
                )
            ) {
                setForm(defaultForm);
                setShowSuccessToast(true);
                setErrors({});

                captchaRef.current.reset();
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};

        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "first_name",
            "first name"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "last_name", "last name");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "company_name",
            "company"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "email", "email");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "identifier",
            "firm number or account number"
        );

        if (form.email && !isValidEmail(form.email)) {
            newErrors.email = "Please enter valid email.";
        }

        if (form.phone && form.phone.length > 0 && form.phone.length !== 14) {
            newErrors.phone = "Please enter a valid phone number.";
        }

        const token = captchaRef.current.getValue();

        if (token === undefined || token === "") {
            newErrors.captcha = "Invalid captcha.";
            setShowCaptchaError(true);
        }

        return newErrors;
    };

    const handleReset = () => {
        setForm(defaultForm);
    };

    useTitle("Request CharlesJonesID");

    return (
        <div>
            <Innerpageheading pageHeadInfo={requestIdPageHeader()} />
            <Breadcrumb breadcrumbInfo={requestId()} />

            <Container>
                <h1 className="get-started-header mt-5 mb-2 px-3">
                    Request a CharlesJonesID
                </h1>

                <p className="inner-heading-text mb-5 px-3">
                    Please provide the following information. Providing this
                    will allow us to send you your individual invitation to
                    create your CharlesJonesID. An email from "Charles Jones
                    Identity" with your invitation will be sent within the next
                    24 hours.
                </p>

                <Container className="justify-content-md-center mb-5">
                    <Form className="form-background">
                        <h2 className="get-started-subheader mb-5">
                            Contact Information
                        </h2>
                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="first_name">
                                    <Form.Label className="formlabel-title">
                                        First Name
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="John"
                                        maxLength="50"
                                        pattern="[A-Za-z]"
                                        value={form.first_name}
                                        onChange={(event) =>
                                            setField(
                                                "first_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.first_name && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.first_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="last_name">
                                    <Form.Label className="formlabel-title">
                                        Last Name
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Carter"
                                        maxLength="50"
                                        pattern="[A-Za-z]"
                                        value={form.last_name}
                                        onChange={(event) =>
                                            setField(
                                                "last_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.last_name && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.last_name}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="email">
                                    <Form.Label className="formlabel-title">
                                        Email
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@email.com"
                                        maxLength="100"
                                        value={form.email}
                                        onChange={(event) =>
                                            setField(
                                                "email",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.email && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.email}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="phone">
                                    <Form.Label className="formlabel-title">
                                        Phone
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        maxLength="14"
                                        minLength="14"
                                        value={form.phone}
                                        onChange={(event) =>
                                            setField(
                                                "phone",
                                                normalizeInput(
                                                    allowASCIIValues(
                                                        event.target.value
                                                    ),
                                                    form.phone
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.phone && "red-border"
                                        }
                                        pattern="(ddd) ddd-ddd"
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.phone}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="company_name">
                                    <Form.Label className="formlabel-title">
                                        Company
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Company Name"
                                        maxLength="100"
                                        value={form.company_name}
                                        onChange={(event) =>
                                            setField(
                                                "company_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.company_name &&
                                            "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.company_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="identifier">
                                    <Form.Label className="formlabel-title">
                                        Firm or Account Number
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="00001"
                                        maxLength="9"
                                        value={form.identifier}
                                        onChange={(event) =>
                                            setField(
                                                "identifier",
                                                removeAlpha(event.target.value)
                                            )
                                        }
                                        className={
                                            !!errors.identifier && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.identifier}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <h2 className="get-started-subheader my-5">
                            Comments or additional information
                        </h2>

                        <Form.Group controlId="message">
                            <textarea
                                placeholder="Please type message here..."
                                maxLength="500"
                                type="textarea"
                                id="message"
                                className={
                                    errors.message
                                        ? "form-control cj-textarea red-border"
                                        : "form-control cj-textarea"
                                }
                                rows={7}
                                onChange={(event) => {
                                    setField(
                                        "message",
                                        allowASCIIValues(event.target.value)
                                    );
                                }}
                                value={form.message}
                            ></textarea>
                            <div className="error-message">
                                {errors.message}
                            </div>
                        </Form.Group>

                        <p className="inner-heading-text mb-4 px-3">
                            If you need assistance with your Firm or Account
                            number, please call us at: {CJ_PHONE} (M-F, 8:00 AM
                            to 5:30 PM ET).
                        </p>

                        <Form.Group className="mt-3">
                            <div>
                                <ReCAPTCHA
                                    sitekey={ReCaptchaKey}
                                    ref={captchaRef}
                                    onChange={() => resetCaptcha()}
                                />
                            </div>

                            {showCaptchaError ? (
                                <div className="error-message">
                                    {errors.captcha}
                                </div>
                            ) : (
                                <></>
                            )}

                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn btn-cj-primary ms-3 mt-3 form-btn form-submit-btn"
                            >
                                SUBMIT REQUEST
                            </Button>

                            <Button
                                type="button"
                                onClick={handleReset}
                                className="btn btn-cj-secondary ms-3 mt-3"
                            >
                                RESET
                            </Button>
                        </Form.Group>
                    </Form>
                </Container>

                <PrivacyPolicy showFull={false} />
            </Container>

            <Toasts
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
                showSuccessToast={showSuccessToast}
                setShowSuccessToast={setShowSuccessToast}
            />
        </div>
    );
}
