import { Container } from "react-bootstrap";
import Breadcrumb from "../../components/common/breadcrumb";
import Innerpageheading from "../../components/common/innerpageheading";
import { notFoundPageHeader } from "../../components/common/pageheader";
import { notFound } from "../../components/common/breadcrumbs";
import Contact from "../../components/common/contact";
import useTitle from "../../utils";


export default function NotFound() {
    useTitle("Page Not Found");

    return (
        <div>
            <Innerpageheading pageHeadInfo={notFoundPageHeader()} />
            <Breadcrumb breadcrumbInfo={notFound()} />

            <Container className="my-5">
                <div className="mx-5 mx-md-3 mx-lg-0">
                    <h1 className="get-started-header mt-5  mb-2 ">
                        Page Not Found
                    </h1>
                    <p>
                        We were not able to find the page you were looking for.
                        If you believe this to be an error please contact us.
                    </p>
                </div>
            </Container>
            <div className="row contact-loaction mt-5">
                <Contact />
            </div>
        </div>
    );
}
