import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { corporateAndUCCServicesPageHeader } from "../../../components/common/pageheader";
import { corporateAndUCCServicesBreadcrumb } from "../../../components/common/breadcrumbs";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import solutionsData from "../../../components/solutions/SolutionsData";
import { useTitle, useMetaDescription } from "../../../utils";


const CorporateAndUCCServices = () => {
    const data = solutionsData[4];
    const Divider = () => {
        return (
            <div className="row pt-5">
                <div className="col-12">
                    <hr stroke="#D4D2E3" className="bottom-content"></hr>
                </div>
            </div>
        );
    };

    useTitle("Corporate UCC Search Services");
    useMetaDescription(data.metaDescription);

    return (
        <>
            <Innerpageheading
                pageHeadInfo={corporateAndUCCServicesPageHeader()}
            />
            <Breadcrumb breadcrumbInfo={corporateAndUCCServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[0]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[1]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[2]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[3]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[4]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[5]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[6]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[7]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default CorporateAndUCCServices;
