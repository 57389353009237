import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import "./index.css";
import { CGI_PATH, CJ_PHONE, US_STATES } from "../../constants";
import { requestAccountPageHeader } from "../../components/common/pageheader";
import { requestAccount } from "../../components/common/breadcrumbs";
import PrivacyPolicy from "../../components/common/PrivacyPolicy";
import Toasts from "../../components/forms/Toasts";
import {
    allowASCIIValues,
    checkAndAddFieldErrorMessage,
    isValidEmail,
    normalizeInput,
    removeAlpha,
    submitForm,
} from "../../components/forms/FormHelpers";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaKey } from "../../constants";
import useTitle from "../../utils";


export default function RequestAccount() {
    const updateSameBillingInfoCheckbox = (changeValue) => {
        setField("match_contact_info", changeValue);
        setSameBillingInfo(changeValue);
    };

    const defaultForm = {
        full_name: "",
        company_name: "",
        phone: "",
        fax: "",
        email: "",
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        zip: "",
        billing_contact_name: "",
        billing_city: "",
        billing_street_1: "",
        billing_street_2: "",
        billing_state: "",
        billing_zip: "",
        billing_fax: "",
        message: "",
        match_contact_info: false,
        captcha: "",
    };

    const captchaRef = useRef(null);
    function resetCaptcha() {
        setShowCaptchaError(false);
    }

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [sameBillingInfo, setSameBillingInfo] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState(defaultForm);

    const setField = (filed, value) => {
        setForm({
            ...form,
            [filed]: value,
        });

        if (!!errors[filed]) {
            setErrors({
                ...errors,
                [filed]: null,
            });
        }
    };

    const buildFormData = () => {
        return {
            full_name: form.full_name,
            company_name: form.company_name,
            phone: form.phone,
            fax: form.fax,
            email: form.email,
            address: {
                street_1: form.street_1,
                street_2: form.street_2,
                city: form.city,
                state: form.state,
                zip: form.zip,
            },
            match_contact_info: form.match_contact_info,
            billing: !form.match_contact_info
                ? {
                      contact_name: form.billing_contact_name,
                      address: {
                          city: form.billing_city,
                          street_1: form.billing_street_1,
                          street_2: form.billing_street_2,
                          state: form.billing_state,
                          zip: form.billing_zip,
                      },
                      fax: form.billing_fax,
                  }
                : {},
            message: form.message,
            token: captchaRef.current.getValue(),
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (
                await submitForm(
                    `${CGI_PATH}request-account.cgi`,
                    buildFormData()
                )
            ) {
                setForm(defaultForm);
                setShowSuccessToast(true);
                captchaRef.current.reset();
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const validateForm = () => {
        let newErrors = {};

        checkAndAddFieldErrorMessage(form, newErrors, "full_name", "name");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "company_name",
            "company"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "email", "email");
        checkAndAddFieldErrorMessage(form, newErrors, "message", "message");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "street_1",
            "street address"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "city", "city");

        if (form.email && !isValidEmail(form.email)) {
            newErrors.email = "Please enter valid email.";
        }

        if (!form.phone || form.phone.length !== 14) {
            newErrors.phone = "Please enter a valid phone number.";
        }

        if (form.fax && form.fax.length !== 14) {
            newErrors.fax = "Please enter a valid phone number.";
        }

        if (!form.state || form.state === 0) {
            newErrors.state = "Please select a state.";
        }

        if (form.zip.length < 5) {
            newErrors.zip = "Please enter a valid zipcode.";
        }

        // Only validate billing info if user has chosen to enter it manually.
        if (!form.match_contact_info) {
            checkAndAddFieldErrorMessage(
                form,
                newErrors,
                "billing_contact_name",
                "name"
            );
            checkAndAddFieldErrorMessage(
                form,
                newErrors,
                "billing_street_1",
                "street address"
            );

            if (!form.billing_fax || form.billing_fax.length !== 14) {
                newErrors.billing_fax = "Please enter a valid phone number.";
            }

            if (!form.billing_state || form.billing_state === 0) {
                newErrors.billing_state = "Please select a state.";
            }

            checkAndAddFieldErrorMessage(
                form,
                newErrors,
                "billing_city",
                "city"
            );

            if (form.billing_zip.length < 5) {
                newErrors.billing_zip = "Please enter a valid zipcode.";
            }
        }

        const token = captchaRef.current.getValue();

        if (token === undefined || token === "") {
            newErrors.captcha = "Invalid captcha.";
            setShowCaptchaError(true);
        }

        return newErrors;
    };

    const handleReset = () => {
        setSameBillingInfo(false);
        setForm(defaultForm);
    };

    useTitle("Request Account");

    return (
        <div>
            <Innerpageheading pageHeadInfo={requestAccountPageHeader()} />
            <Breadcrumb breadcrumbInfo={requestAccount()} />

            <Container>
                <h1 className="get-started-header mt-5 mb-2 px-3">
                    Request an Account
                </h1>

                <p className="inner-heading-text mb-5 px-3">
                    If you have any questions concerning the filling out of this
                    form, please e-mail us at{" "}
                    <span className="text-decoration-underline">
                        info@charlesjones.com
                    </span>
                    &nbsp;or call {CJ_PHONE} and ask for our Customer Service
                    Department. We will contact you shortly with account
                    information upon review and approval.
                </p>

                <Container className="justify-content-md-center mb-5">
                    <Form className="form-background">
                        <h2 className="get-started-subheader mb-5">
                            Contact Information
                        </h2>
                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="full_name">
                                    <Form.Label className="formlabel-title">
                                        Name
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="John Carter"
                                        maxLength="100"
                                        pattern="[A-Za-z]"
                                        value={form.full_name}
                                        onChange={(event) =>
                                            setField(
                                                "full_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.full_name && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.full_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="street_1">
                                    <Form.Label className="formlabel-title">
                                        Address
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="123 Main Street"
                                        maxLength="100"
                                        value={form.street_1}
                                        onChange={(event) =>
                                            setField(
                                                "street_1",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.street_1 && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.street_1}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="company_name">
                                    <Form.Label className="formlabel-title">
                                        Company
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Company Name"
                                        maxLength="100"
                                        value={form.company_name}
                                        onChange={(event) =>
                                            setField(
                                                "company_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.company_name &&
                                            "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.company_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="street_2">
                                    <Form.Label className="formlabel-title">
                                        Address 2
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Suite 123"
                                        maxLength="100"
                                        value={form.street_2}
                                        onChange={(event) =>
                                            setField(
                                                "street_2",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="phone">
                                    <Form.Label className="formlabel-title">
                                        Phone
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        maxLength="14"
                                        minLength="14"
                                        value={form.phone}
                                        onChange={(event) =>
                                            setField(
                                                "phone",
                                                normalizeInput(
                                                    event.target.value,
                                                    form.phone
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.phone && "red-border"
                                        }
                                        pattern="(ddd) ddd-ddd"
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.phone}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="city">
                                    <Form.Label className="formlabel-title">
                                        City
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Trenton"
                                        maxLength="50"
                                        value={form.city}
                                        onChange={(event) =>
                                            setField(
                                                "city",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.city && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.city}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="fax">
                                    <Form.Label className="formlabel-title">
                                        Fax
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        maxLength="14"
                                        value={form.fax}
                                        onChange={(event) =>
                                            setField(
                                                "fax",
                                                normalizeInput(
                                                    event.target.value,
                                                    form.fax
                                                )
                                            )
                                        }
                                        pattern="(ddd) ddd-ddd"
                                        className={!!errors.fax && "red-border"}
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.fax}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="state">
                                    <Form.Label className="formlabel-title">
                                        State
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        placeholder=""
                                        value={form.state}
                                        onChange={(event) =>
                                            setField(
                                                "state",
                                                event.target.value
                                            )
                                        }
                                        className={
                                            !!errors.state && "red-border"
                                        }
                                    >
                                        <option value=""> Select </option>(
                                        {US_STATES.map((state) => {
                                            return (
                                                <option
                                                    key={state.abbreviation}
                                                    value={state.abbreviation}
                                                >
                                                    {state.abbreviation}
                                                </option>
                                            );
                                        })}
                                        )
                                    </Form.Select>
                                </Form.Group>
                                <div className="error-message">
                                    {errors.state}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="email">
                                    <Form.Label className="formlabel-title">
                                        Email
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@email.com"
                                        maxLength="50"
                                        value={form.email}
                                        onChange={(event) =>
                                            setField(
                                                "email",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.email && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.email}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="zip">
                                    <Form.Label className="formlabel-title">
                                        Zipcode
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="12345"
                                        maxLength="5"
                                        value={form.zip}
                                        onChange={(event) =>
                                            setField(
                                                "zip",
                                                removeAlpha(event.target.value)
                                            )
                                        }
                                        className={!!errors.zip && "red-border"}
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.zip}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <h2 className="get-started-subheader my-5">
                            Billing Information
                        </h2>

                        <Form.Group controlId="match_contact_info">
                            <Form.Check
                                type="checkbox"
                                label="Same as Contact Information"
                                checked={form.match_contact_info}
                                onChange={(event) => {
                                    updateSameBillingInfoCheckbox(
                                        event.target.checked
                                    );
                                }}
                                name="match_contact_info"
                                id="match_contact_info"
                            />
                        </Form.Group>

                        <div className={sameBillingInfo ? "d-none" : ""}>
                            <Row className="mt-5 mb-lg-3">
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_contact_name">
                                        <Form.Label className="formlabel-title">
                                            Contact Name
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="John Carter"
                                            maxLength="100"
                                            pattern="[A-Za-z]"
                                            value={form.billing_contact_name}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_contact_name",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.billing_contact_name &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                        <div className="error-message">
                                            {errors.billing_contact_name}
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_city">
                                        <Form.Label className="formlabel-title">
                                            City
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Trenton"
                                            maxLength="50"
                                            value={form.billing_city}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_city",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.billing_city &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="error-message">
                                        {errors.billing_city}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-lg-3">
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_street_1">
                                        <Form.Label className="formlabel-title">
                                            Address
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="123 Main Street"
                                            maxLength="100"
                                            value={form.billing_street_1}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_street_1",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.billing_street_1 &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="error-message">
                                        {errors.billing_street_1}
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_state">
                                        <Form.Label className="formlabel-title">
                                            State
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Select
                                            placeholder=""
                                            value={form.billing_state}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_state",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.billing_state &&
                                                "red-border"
                                            }
                                        >
                                            <option value=""> Select </option>
                                            {US_STATES.map((state) => {
                                                return (
                                                    <option
                                                        key={state.abbreviation}
                                                        value={
                                                            state.abbreviation
                                                        }
                                                    >
                                                        {state.abbreviation}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="error-message">
                                        {errors.billing_state}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-lg-3">
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_street_2">
                                        <Form.Label className="formlabel-title">
                                            Address 2
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Suite 123"
                                            maxLength="100"
                                            value={form.billing_street_2}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_street_2",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_zip">
                                        <Form.Label className="formlabel-title">
                                            Zipcode
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="12345"
                                            maxLength="5"
                                            value={form.billing_zip}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_zip",
                                                    removeAlpha(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.billing_zip &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="error-message">
                                        {errors.billing_zip}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-lg-3">
                                <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                    <Form.Group controlId="billing_fax">
                                        <Form.Label className="formlabel-title">
                                            Fax
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="(123) 456-7890"
                                            maxLength="100"
                                            value={form.billing_fax}
                                            onChange={(event) =>
                                                setField(
                                                    "billing_fax",
                                                    normalizeInput(
                                                        event.target.value,
                                                        form.fax
                                                    )
                                                )
                                            }
                                            pattern="(ddd) ddd-ddd"
                                            className={
                                                !!errors.billing_fax &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="error-message">
                                        {errors.billing_fax}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <h2 className="get-started-subheader my-5">
                            Comments or additional information
                        </h2>

                        <Form.Group controlId="message">
                            <textarea
                                placeholder="Please type message here..."
                                maxLength="500"
                                type="textarea"
                                id="message"
                                className={
                                    errors.message
                                        ? "form-control cj-textarea red-border"
                                        : "form-control cj-textarea"
                                }
                                rows={7}
                                onChange={(event) => {
                                    setField(
                                        "message",
                                        allowASCIIValues(event.target.value)
                                    );
                                }}
                                value={form.message}
                            ></textarea>
                            <div className="error-message">
                                {errors.message}
                            </div>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <div>
                                <ReCAPTCHA
                                    sitekey={ReCaptchaKey}
                                    ref={captchaRef}
                                    onChange={() => resetCaptcha()}
                                />
                            </div>

                            {showCaptchaError ? (
                                <div className="error-message">
                                    {errors.captcha}
                                </div>
                            ) : (
                                <></>
                            )}

                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn btn-cj-primary ms-3 mt-3 form-btn form-submit-btn"
                            >
                                SUBMIT REQUEST
                            </Button>
                            <Button
                                type="button"
                                onClick={handleReset}
                                className="btn btn-cj-secondary ms-3 mt-3"
                            >
                                RESET
                            </Button>
                        </Form.Group>
                    </Form>
                </Container>
                <PrivacyPolicy showFull={false} />
            </Container>

            <Toasts
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
                showSuccessToast={showSuccessToast}
                setShowSuccessToast={setShowSuccessToast}
            />
        </div>
    );
}
