import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { countySearchServicesPageHeader } from "../../../components/common/pageheader";
import { countySearchServicesBreadcrumb } from "../../../components/common/breadcrumbs";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import solutionsData from "../../../components/solutions/SolutionsData";
import { useTitle, useMetaDescription } from "../../../utils";


const CountySearchServices = () => {
    const data = solutionsData[3];

    useTitle(data.solutionTitle);
    useMetaDescription(data.metaDescription);

    return (
        <>
            <Innerpageheading pageHeadInfo={countySearchServicesPageHeader()} />
            <Breadcrumb breadcrumbInfo={countySearchServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[0]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default CountySearchServices;
