import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { taxSearchesPageHeader } from "../../../components/common/pageheader";
import { taxSearchesBreadcrumb } from "../../../components/common/breadcrumbs";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import solutionsData from "../../../components/solutions/SolutionsData";
import { useTitle, useMetaDescription } from "../../../utils";

const TaxSearches = () => {
    const data = solutionsData[1];

    useTitle("NJ Tax and Assessment Search, Property Lien, Municipal & Certifications | PA & New Jersey");
    useMetaDescription(data.metaDescription);

    return (
        <>
            <Innerpageheading pageHeadInfo={taxSearchesPageHeader()} />
            <Breadcrumb breadcrumbInfo={taxSearchesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[0]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <ServiceDetails
                            data={data.services[1]}
                            showHeader={false}
                            showTextExpand={true}
                        />
                    </div>
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default TaxSearches;
