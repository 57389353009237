const faqData = [
    {
        question: "Why might you need to order a Child Support Judgment Search?",
        answer: "NJ State legislation requires attorneys in civil" +
                "actions to ascertain whether their clients are child" +
                "support judgment debtors before releasing a monetary" +
                "award or settlement.",
        links: ["https://legiscan.com/NJ/text/S2348/id/668620"]
    },
    {
        question: "Why might you need to order a Patriot Name Search?",
        answer: "NJ State legislation requires attorneys in civil" +
                "actions to ascertain whether their clients are child" +
                "support judgment debtors before releasing a monetary" +
                "award or settlement.",
        links: [
            "https://www.treasury.gov/resource-center/sanctions/Programs/Documents/terror.pdf",
            "https://www.treasury.gov/resource-center/sanctions/Documents/13268.pdf",
            "https://www.gpo.gov/fdsys/pkg/FR-2012-05-03/pdf/2012-10884.pdf",
            "https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/consolidated.aspx",
            "https://www.congress.gov/bill/107th-congress/house-bill/3162"
        ]
    },
    {
        question: "Why might you need to order a Flood Search?",
        answer: "Flood Disaster Protection Act -",
        links: ["https://www.fdic.gov/regulations/laws/rules/6000-2400.html"]
    },
    {
        question: "Why might you need to order a Tideland Search?",
        answer: "O'NEILL v. State Hwy. Dept.",
        links: ["http://law.justia.com/cases/new-jersey/supreme-court/1967/50-n-j-307-0.html"]
    },
    {
        question: "What liens can be uncovered in a NJ property lien search through Charles Jones? ",
        answer: "A NJ municipal tax search with the Charles Jones platform can reveal " +
                "various liens, including unpaid taxes, water and sewer charges, any " +
                "special assessments and municipal debts. These searches provide a " +
                "clear picture of the property's financial standing before closing.",
        links: []
    },
    {
        question: "Can PA tax certifications reveal hidden or outstanding property debts?",
        answer: "Yes, PA tax certifications offer insight into unpaid taxes and municipal " +
                "liens, helping identify hidden debts. This ensures all property liabilities " +
                "are addressed, protecting buyers from unexpected financial obligations post-purchase.",
        links: []
    },
    {
        question: "What are the most important searches for commercial due diligence when engaging in a transaction?",
        answer: "For commercial due diligence, key searches include UCC filings, corporate " +
                "status verification, litigation history, and judgment and lien searches. " +
                "These searches provide insight into potential risks such as undisclosed liabilities, " +
                "financial encumbrances, or legal disputes related to the transaction.",
        links: []
    },
    {
        question: "Can Charles Jones provide LLC historical data through an LLC search for NJ businesses?",
        answer: "Yes, you can access detailed LLC NJ records, including historical filings, " +
                "status changes, and other relevant data with Charles Jones. Our service " +
                "ensures that businesses can conduct thorough due diligence by accessing a " +
                "complete history of New Jersey LLCs.",
        links: []
    },
    {
        question: "What is the difference between a Certificate of Good Standing and a Legal Existence Certificate?",
        answer: "A Good Standing Certificate confirms that an entity is current with its " +
                "filings and taxes, which is important when doing business with a company. " +
                "A Legal Existence Certificate only verifies that the entity is officially " +
                "registered with the state.",
        links: []
    },
]

export default faqData;
