import React from "react";
import "./testimonialscard.css";

const TestimonialsCard = ({ data }) => {
    return (
        <div className="testimonialscard shadow-sm shadow shadow-lg bg-white mx-auto">
            <div className="card-body text-center">
                <h2
                    className="card-title mb-3"
                    style={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "#0D0D0D",
                        lineHeight: "24px",
                    }}
                >
                    {data.title}
                </h2>

                <p className="testimonial-card-desc1">
                    {data.desc}
                </p>

                <h5 className="testimonial-name">{data.author}</h5>

                <p className="testimonial-company">
                    {data.company}
                </p>
            </div>
        </div>
    );
};

export default TestimonialsCard;
