import Container from "react-bootstrap/Container";
import { useState } from "react";
import "./solutions.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import solutionsData from "../../components/solutions/SolutionsData";
import { Link } from "react-router-dom";
import Innerpageheading from "../../components/common/innerpageheading";
import Innerpageinnerheading from "../../components/common/innerpageinnerheading";
import Breadcrumb from "../../components/common/breadcrumb";
import Contact from "../../components/common/contact";
import {
    solutionSummaryPageHeader,
    solutionSummaryPageSubHeader,
} from "../../components/common/pageheader";
import { solutionSummaryBreadcrumb } from "../../components/common/breadcrumbs";
import useTitle from "../../utils";


const AvailableForItem = ({ item }) => {
    return (
        <div className="row pt-1">
            <div className="col">
                <img src="./img/where_to_vote.svg" alt=""></img>{" "}
                <span>{item}</span>
            </div>
        </div>
    );
};

const AccordionServiceItem = ({ service }) => {
    useTitle("Solutions");

    return (
        <>
            <div className="solution-service-container">
                <div className="row pt-4" style={{ width: "100%" }}>
                    <div className="col-10 service-item-title">
                        {service.serviceTitle}
                    </div>
                    <div className="col-2 service-item-image">
                        <Link to={service.url}>
                            {" "}
                            <img
                                alt="button"
                                className="secondary-circle-button"
                            ></img>
                        </Link>
                    </div>
                </div>

                <div className="row service-item-lable">
                    <div className="col-lg-3 col-md-4 col-xl-2 col-sm-6 col-6">
                        AVAILABLE FOR
                    </div>
                    <div className="col-lg-9 col-md-8 col-xl-10 col-sm-6 col-6">
                        CERTIFIED SEARCH
                    </div>
                </div>

                <div className="row service-item-lable2">
                    <div className="col-lg-3 col-md-4 col-xl-2 col-sm-6 col-6">
                        {service.availableFor.map((availableFor, index) => (
                            <AvailableForItem key={index} item={availableFor} />
                        ))}
                    </div>
                    <div className="col-lg-9 col-md-8 col-xl-10 col-sm-6 col-6">
                        <div
                            className={
                                service.certified === "No"
                                    ? "fade-availability"
                                    : ""
                            }
                        >
                            <img src="./img/verified_user.svg" alt=""></img>
                            <span>{service.certified}</span>
                        </div>
                    </div>
                </div>

                <div className="margin-bottom-1r"></div>

                {service.showDivider ? (
                    <div className="bordor-bottom-1"></div>
                ) : null}
            </div>
        </>
    );
};

//  accordionitem component
const AccordionItem = ({ solutionItem, isOpen, onClick }) => {
    return (
        <div className="accordion-wrapper">
            <button
                className={`solution-item-container ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <div className="row justify-content-between py-2  py-md-3">
                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-none d-sm-flex align-items-center">
                        <img
                            src={solutionItem.solutionTitleImg}
                            alt=""
                            className="img-fluid"
                        ></img>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-10 d-flex text-left">
                        <p className="accordion-header align-items-center ps-2 p-md-0">
                            {solutionItem.solutionTitle}
                        </p>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex align-items-center justify-content-end">
                        <RiArrowDropDownLine
                            className={`arrow ${isOpen ? "active" : ""}`}
                            style={{ width: "55px", height: "55px" }}
                        />
                    </div>
                </div>
            </button>

            <div
                className="solution-item-content-container"
                style={isOpen ? { height: "auto" } : { height: "0px" }}
            >
                <div className="solution-item-content">
                    {solutionItem.services.map((serviceItem, index) => (
                        <AccordionServiceItem
                            key={index}
                            service={serviceItem}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default function Solutions() {
    const [openItems, setOpenItems] = useState(solutionsData.map(() => true));

    const toggleAll = () => {
        const allOpen = openItems.every((item) => item === true);
        setOpenItems(openItems.map(() => !allOpen));
    };

    const handleItemClick = (index) => {
        const newOpenItems = [...openItems];
        newOpenItems[index] = !newOpenItems[index];
        setOpenItems(newOpenItems);
    };

    return (
        <div>
            <Innerpageheading pageHeadInfo={solutionSummaryPageHeader()} />
            <Breadcrumb breadcrumbInfo={solutionSummaryBreadcrumb()} />
            <Innerpageinnerheading innerText={solutionSummaryPageSubHeader()} />
            <Container
                fluid
                className="px-3 px-md-5 xl-max-size"
                style={{ maxWidth: "1500px !important" }}
            >
                <div className="row">
                    <div className="col-12 toggel-all">
                        <div onClick={toggleAll}>
                            <span className="toggle-wrapper ">
                                {openItems.every((item) => item)
                                    ? "CLOSE ALL"
                                    : "OPEN ALL"}
                            </span>
                            <RiArrowDropDownLine
                                className={
                                    openItems.every((item) => item)
                                        ? "toogle-dropdownline-close"
                                        : "toogle-dropdownline-open"
                                }
                            ></RiArrowDropDownLine>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="accordion-container">
                            {solutionsData.map((item, index) => (
                                <AccordionItem
                                    key={index}
                                    solutionItem={item}
                                    isOpen={openItems[index]}
                                    onClick={() => handleItemClick(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </div>
    );
}
